<template>
    <div class="main">
        <div class="menu">
            <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect"
                background-color="rgb(250, 250, 250)">
                <el-menu-item index="1">库存仓位管理</el-menu-item>
            </el-menu>
        </div>
        <div class="change_select_menu">
            <el-button style="margin-left: 20px;margin-right: 20px;" @click="change_updata"
                :loading="isSending">更改存放位置</el-button>
            箱号：
            <el-input placeholder="请输入箱号" v-model="select_input" @keydown.native="handleKeydown"
                style="margin-right: 20px;width: 10%;">
            </el-input>
            仓位：
            <el-select v-model="filterText" placeholder="选择仓位" filterable>
                <el-option v-for="item in ware" :key="item.value" :label="item.text" :value="item.value">
                </el-option>

            </el-select>
            <el-button style="margin-left: 5px;" @click="local_d" :loading="isSending9">仓位删除</el-button>
            <el-input placeholder="仓位增添" v-model="warehouse_d" style="margin-right: 5px;width: 10%;margin-left: 37px;">
            </el-input>
            <el-button style="margin-left: 5px;" @click="local_add" :loading="isSending8">仓位增添</el-button>
            <el-button style="margin-left: 20px;" size="mini" @click="download_mo" type="primary" plain>下载批量上传模版</el-button>
            <el-upload class="upload-demo" :http-request="httpRequest1" :on-preview="handlePreview" :file-list="fileList"
                :show-file-list="false">
                <el-button style="margin-left: 1130px;">批量更改存放位置</el-button>
            </el-upload>
            <el-upload :http-request="httpRequest2" :on-preview="handlePreview" :file-list="fileList"
                :show-file-list="false">
                <el-button style="margin-left: 1130px;margin-top:10px">批量清空存放位置</el-button>
            </el-upload>
            <el-button style="margin-left: 1130px;margin-top:10px" @click="page_up">上架商品</el-button>
            <el-button style="margin-left: 1130px;margin-top:10px" @click="page_down">下架商品</el-button>

        </div>
        <div class="change">
            <div class="change_div1">
                <div class="change_table1">
                    <el-table :data="tableData" style="width: 100%" height="100%" stripe>
                        <el-table-column width="15">
                        </el-table-column>
                        <el-table-column prop="PA号" label="PA号" width="400">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper" style="padding-top: 5px;">
                                    {{ scope.row.PA号 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="仓位" label="仓位" width="400">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper" style="padding-top: 5px;">
                                    {{ scope.row.仓位 }}
                                </div>
                            </template>
                        </el-table-column>

                    </el-table>
                </div>
            </div>
        </div>
        <el-dialog title="上架商品" :visible="dialogTableVisible_up" :before-close="handleClose_up">
            箱号：
            <el-input placeholder="请输入箱号" v-model="select_input_up" ref="inputA" @keydown.native="handleKeydown_a"
                style="margin-right: 20px;width: 20%;">
            </el-input>
            仓位：
            <el-input placeholder="请输入仓位" v-model="cw_input_up" ref="inputB" @keydown.native="handleKeydown_b"
                style="margin-right: 20px;width: 20%;">
            </el-input>
            <el-button style="margin-left: 20px;margin-right: 20px;" @click="change_updata_up"
                :loading="isSending_up">点击上架商品</el-button>
            <el-table :data="tableData_up">
                <el-table-column width="15">
                </el-table-column>
                <el-table-column prop="PA号" label="PA号" width="300">
                    <template slot-scope="scope">
                        <div slot="reference" class="name-wrapper" style="padding-top: 5px;">
                            {{ scope.row.PA号 }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="仓位" label="仓位" width="300">
                    <template slot-scope="scope">
                        <div slot="reference" class="name-wrapper" style="padding-top: 5px;">
                            {{ scope.row.仓位 }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="170">
                    <template slot-scope="scope">
                        <el-popconfirm title="确认删除吗？" @confirm="deleteRow_up(scope.$index)">
                            <el-button size="small" plain type="danger" slot="reference"
                                style="float: right;margin-left: 3px;">删除</el-button>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
        <el-dialog title="下架商品" :visible="dialogTableVisible_down" :before-close="handleClose_down">
            箱号：
            <el-input placeholder="请输入箱号" v-model="select_input_down" ref="inputC" @keydown.native="handleKeydown_c"
                style="margin-right: 20px;width: 20%;">
            </el-input>
            <el-button style="margin-left: 20px;margin-right: 20px;" @click="change_updata_down"
                :loading="isSending_down">点击下架商品</el-button>
            <el-table :data="tableData_down" max-height="500">
                <el-table-column width="15">
                </el-table-column>
                <el-table-column prop="PA号" label="PA号" width="300">
                    <template slot-scope="scope">
                        <div slot="reference" class="name-wrapper" style="padding-top: 5px;">
                            {{ scope.row.PA号 }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="仓位" label="仓位" width="300">
                    <template slot-scope="scope">
                        <div slot="reference" class="name-wrapper" style="padding-top: 5px;">
                            {{ scope.row.仓位 }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="170">
                    <template slot-scope="scope">
                        <el-popconfirm title="确认删除吗？" @confirm="deleteRow_down(scope.$index)">
                            <el-button size="small" plain type="danger" slot="reference"
                                style="float: right;margin-left: 3px;">删除</el-button>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            activeIndex: '1',
            select_input: '',
            select_input_up: '',
            select_input_down:'',
            cw_input_up: '',
            isSending: false,
            isSending_up: false,
            isSending_down: false,
            isSending8: false,
            isSending9: false,
            dialogTableVisible_up: false,
            dialogTableVisible_down: false,
            form: {},
            tableData: [],
            tableData_up: [],
            tableData_down: [],
            ware: [],
            warehouse_d: '',
            filterText: '',
            monitor: []
        }
    },
    created() {
        this.init()
    },
    methods: {
        init() {
            this.$axios.get('/vue/change/change_all').then(res => {
                console.log(res);
                this.tableData = res.data
                this.warehouse()
            })
                .console(error => {
                    console.log(error)
                })
        },
        page_up() {
            this.dialogTableVisible_up = true
        },
        page_down() {
            this.dialogTableVisible_down = true
        },
        onlyinit() {
            this.$axios.get('/vue/change/change_all').then(res => {
                console.log(res);
                this.tableData = res.data
            })
                .console(error => {
                    console.log(error)
                })
        },
        warehouse() {
            this.$axios.get('/vue/change/change_warehouse').then(res => {
                console.log(res);
                this.ware = res.data
            })
                .console(error => {
                    console.log(error)
                })
        },
        handleKeydown(event) {
            if (event.key === 'Enter') {
                this.handleEnter();
            }
        },
        handleKeydown_a(event) {
            if (event.key === 'Enter') {
                if(this.select_input_up == '') {
                    alert('箱号不能为空')
                }
                if (this.cw_input_up == '') {
                    this.$nextTick(() => {
                        this.$refs.inputB.$el.querySelector('input').focus();
                    });
                }
                else {
                    this.handleEnter_up()
                }
            }
        },
        handleKeydown_b(event) {
            if (event.key === 'Enter') {
                if(this.cw_input_up == '') {
                    alert('仓位不能为空')
                }
                if (this.select_input_up == '') {
                    this.$nextTick(() => {
                        this.$refs.inputA.$el.querySelector('input').focus();
                    });
                }
                else {
                    this.handleEnter_up()
                }
            }
        },
        handleKeydown_c(event) {
            if (event.key === 'Enter') {
                if (this.select_input_down == ''){
                    alert('箱号不能为空')
                }else{
                    this.handleEnter_down()
                }
            }
        },
        handleEnter_up() {
            this.tableData_up.push({ PA号: this.select_input_up, 仓位: this.cw_input_up })
            this.select_input_up = ''
            this.cw_input_up = ''
            this.$refs.inputA.$el.querySelector('input').focus();
        },
        handleEnter_down() {
            this.tableData_down.push({ PA号: this.select_input_down, 仓位: '' })
            this.select_input_down = ''
            this.$refs.inputC.$el.querySelector('input').focus();
        },
        deleteRow_up(index) {
            this.tableData_up.splice(index, 1); // 使用 splice 删除指定索引的数据
        },
        deleteRow_down(index) {
            this.tableData_down.splice(index, 1); // 使用 splice 删除指定索引的数据
        },
        handleEnter() {
            // 执行回车键对应的操作
            if (this.filterText == '') {
                alert('请先选择仓位')
            }
            else if (this.select_input == '') {
                alert('请先输入箱号')
            } else {
                // this.$axios.post('/vue/change/change_input', { pa: this.select_input, cw: this.filterText }).then(res => {
                //     console.log(res)
                //     this.select_input = ''
                //     this.onlyinit()
                // }).catch(error => {
                //     console.log(error)
                //     // alert(error)
                // })
                this.tableData.push({ PA号: this.select_input, 仓位: this.filterText })
                this.select_input = ''
            }
        },
        handleClose_up() {
            this.dialogTableVisible_up = false
        },
        handleClose_down() {
            this.dialogTableVisible_down = false
        },
        delete_in(pa, cw) {
            this.$axios.post('/vue/change/delete_in', { pa: pa, cw: cw }).then(res => {
                console.log(res)
                if (res.code == 200) {
                    alert('删除成功')
                } else {
                    alert('删除失败')
                }
                this.init()
            }).catch(error => {
                console.log(error)
                // alert(error)
            })
        },
        local_add() {
            if (this.warehouse_d == '') {
                alert('请先输入仓位')
            } else {
                this.isSending8 = true
                this.$axios.post('/vue/change/local_add', { cw: this.warehouse_d }).then(res => {
                    console.log(res)
                    if (res.code == 200) {
                        alert('添加成功')
                    } else {
                        alert('添加失败')
                    }
                    this.warehouse_d = ''
                    this.isSending8 = false
                    this.init()
                }).catch(error => {
                    console.log(error)
                    // alert(error)
                    this.isSending8 = false
                })
            }
        },
        local_d() {
            if (this.filterText == '') {
                alert('请先选择仓位')
            } else {
                this.isSending9 = true
                this.$axios.post('/vue/change/local_d', { cw: this.filterText }).then(res => {
                    console.log(res)
                    if (res.code == 200) {
                        alert('删除成功')
                    } else {
                        alert('删除失败')
                    }
                    this.filterText = ''
                    this.isSending9 = false
                    this.init()
                }).catch(error => {
                    console.log(error)
                    // alert(error)
                    this.isSending9 = false
                })
            }
        },
        change_updata() {
            this.isSending = true
            this.$axios.post('/vue/change/change_updata', this.tableData).then(res => {
                console.log(res)
                if (res.code == 200) {
                    alert('更改成功')
                } else {
                    alert('更改失败')
                }
                this.isSending = false
                this.tableData = []
                this.warehouse()
            }).catch(error => {
                console.log(error)
                // alert(error)
                this.isSending = false
            })
        },
        change_updata_up() {
            this.$confirm('此操作将上架表格的内容, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$message({
                    type: 'success',
					message: '操作成功!'
                })
                this.isSending_up = true
            this.$axios.post('/vue/change/change_updata', this.tableData_up).then(res => {
                console.log(res)
                if (res.code == 200) {
                    alert('上架成功')
                } else {
                    alert('上架失败，请检查表格内容是否正确')
                }
                this.isSending_up = false
                this.tableData_up = []
            }).catch(error => {
                console.log(error)
                // alert("发生错误，请检查扫描的内容是否有误" + error)
                this.isSending_up = false
            })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消操作'
                })
            })
        },
        change_updata_down() {
            this.$confirm('此操作将下架表格的内容, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$message({
                    type: 'success',
					message: '操作成功!'
                })
                this.isSending_down = true
                this.$axios.post('/vue/change/change_updata', this.tableData_down).then(res => {
                    console.log(res)
                    if (res.code == 200) {
                        alert('下架成功')
                    } else {
                        alert('下架失败，请检查表格内容是否正确')
                    }
                    this.isSending_down = false
                    this.tableData_down = []
                }).catch(error => {
                    console.log(error)
                    // alert("发生错误，请检查扫描的内容是否有误" + error)
                    this.isSending_down = false
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消操作'
                })
            })
        },
        download_mo() {
            let moname = '批量上传箱号换仓模版'
            let url = "https://kebeidianzi.com/vue/change/change_download_mo/" + moname + '.xlsx'
            this.$axios.get(`/vue/change/change_download_mo/` + moname + '.xlsx').catch(error => {
                console.log("错误", error)
            })
            window.open(url, '_blank')
        },
        httpRequest1(param) {
            if (this.filterText == '') {
                alert('请先选择仓位')
            } else {
                let fileObj = param.file; // 相当于input里取得的files
                let data = new FormData(); // FormData 对象
                data.append("file", fileObj); // 文件对象
                data.append("cw", this.filterText)
                this.$axios.post('/vue/change/change_upload_in', data, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                }).then(res => {
                    if (res.code == 200) {
                        3
                        console.log("结果：", res);
                        alert('更改成功')
                    } else {
                        alert('更改失败')
                    }
                    this.isSending_register = false
                }).catch(error => {
                    console.log("错误：", error);
                    alert('发生错误:==' + error)
                });
            }
        },
        httpRequest2(param) {
            let fileObj = param.file; // 相当于input里取得的files
            let data = new FormData(); // FormData 对象
            data.append("file", fileObj); // 文件对象
            this.$axios.post('/vue/change/change_upload_in2', data, {
                headers: { 'Content-Type': 'multipart/form-data' }
            }).then(res => {
                if (res.code == 200) {
                    3
                    console.log("结果：", res);
                    alert('更改成功')
                } else {
                    alert('更改失败')
                }
                this.isSending_register = false
            }).catch(error => {
                console.log("错误：", error);
                alert('发生错误:==' + error)
            });
        },
        handlePreview(file) {
            console.log(file);
        },
    }
}
</script>
  
<style>
.main {
    height: 100%;
    width: 100%;

}

.change_select_menu {
    width: 100%;
    height: 4.5%;
    background-color: rgb(250, 250, 250);
}

.change {
    /* border: 1px solid red; */
    height: 88.9%;
}

.change_div1 {
    width: 60%;
    height: 98%;
    margin: 20px;
    margin-top: 0px;
    float: left;

}

.change_table1 {
    width: 100%;
    border: 1px solid rgb(207, 207, 207);
    height: 92%;

}

.change_table2 {
    width: 1500px;
    border: 2px solid rgb(207, 207, 207);
    height: 92%;
    margin-top: 47px;

}
</style>

<style scoped="scoped">
::v-deep .warm tbody tr>td {
    background-color: rgba(236, 236, 236, 0.8);
}
</style>