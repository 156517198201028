<template>
    <div class="review_main">
        <span> {{ rrname }} </span>
        
        <div class="review_table_right" v-for="bottoncolor in reviewtabledata" :key="bottoncolor">
            <div class="review_table_sp1">
                <el-button
                    :type="(bottoncolor.listing评审 == '未评审' ? 'info' : (bottoncolor.listing评审 == '评审通过' ? 'success' : (bottoncolor.listing评审 == '评审中' ? 'primary' : (bottoncolor.listing评审 == '延期' ? 'warning' :  (bottoncolor.listing评审 == '延期审核中' ? 'cyan' : 'danger')))))"
                    circle class="review_botton" @click="select3()">listing评审</el-button>
            </div>
            <div class="review_table_bottom">
                
            </div>
            <div class="review_table_sp2">
                <el-button
                    :type="(bottoncolor.推广评审 == '未评审' ? 'info' : (bottoncolor.推广评审 == '评审通过' ? 'success' : (bottoncolor.推广评审 == '评审中' ? 'primary' : (bottoncolor.推广评审 == '延期' ? 'warning' :  (bottoncolor.推广评审 == '延期审核中' ? 'cyan' : 'danger')))))"
                    circle class="review_botton" @click="select2()">推广评审
                </el-button>
            </div>
            <el-button class="review_botton3"  v-if="bottoncolor.项目结项评审 == '未评审'"
            :type="(bottoncolor.项目结项评审 == '未评审' ? 'info' : (bottoncolor.项目结项评审 == '评审通过' ? 'success' : 'danger'))">
                        未结项
                </el-button>
                <el-button class="review_botton3" v-else-if="bottoncolor.项目结项评审 == '评审通过'"
            :type="(bottoncolor.项目结项评审 == '未评审' ? 'info' : (bottoncolor.项目结项评审 == '评审通过' ? 'success' : 'danger'))">
                        已结项
                </el-button>
        </div>
<!-- ---------------------------------------------------------------------------------------------- -->
        <el-dialog :title="title" :visible="dialogVisible1" width="750px" :before-close="handleClose1">
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目名称" prop="项目名称">
                    <el-input v-model="form.项目名称" autocomplete="off" :disabled="true" style="width: 130%;" />
                </el-form-item>
                <el-form-item>
                    <el-tag style="margin-left: 180%;"
                        :type="(form.评审状态 == '未评审' ? 'info' : (form.评审状态 == '评审通过' ? 'success' : (form.评审状态 == '评审中' ? 'primary' : (form.评审状态 == '延期' ? 'warning' : 'danger'))))">
                        {{ form.评审状态 }}</el-tag>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目内容" prop="项目内容">
                    <el-input type="textarea" :rows="3" v-model="form.项目内容" autocomplete="off" :disabled="true"
                        style="width: 130%;" />
                </el-form-item>
                <el-form-item>
                        <el-popover placement="right" width="600" trigger="click">
                            <el-table :data="gridData">
                                <el-table-column width="150" property="评审人" label="评审人"></el-table-column>
                                <el-table-column width="150" property="评审结果" label="评审结果"></el-table-column>
                                <el-table-column width="200" property="评审意见" label="评审意见"></el-table-column>
                                <!-- <el-table-column width="150" property="延期时间" label="延期时间（若延期）"></el-table-column> -->
                            </el-table>
                            <el-button type="info" slot="reference" plain
                                style="margin-left: 128%;height: 50px;margin-top: 10px;">评审详情</el-button>
                        </el-popover>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="CR结论" prop="CR结论">
                    <el-input type="textarea" :rows="3" v-model="form.CR结论" autocomplete="off" placeholder=""
                        style="width: 130%;" />
                </el-form-item>
                <el-form-item>
                    <el-popover placement="right" width="550" trigger="click">
                        <el-table :data="fileData" max-height="500px">
                            <el-table-column width="200" property="文件名" label="文件名"></el-table-column>
                            <el-table-column width="150" property="上传时间" label="上传时间"></el-table-column>
                            <el-table-column width="150" property="下载">
                                <template slot-scope="scope">
                                    <el-button size="mini" plain type="danger" slot="reference" style="float: right;"
                                        @click="delect_load(scope.row)">删除
                                    </el-button>
                                    <el-button size="mini" plain type="primary" slot="reference"
                                        style="float: right;margin-right: 5px;" @click="download(scope.row)">下载</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-button type="info" slot="reference" plain
                            style="margin-left: 128%;height: 50px;margin-top: 10px;">下载附件</el-button>
                    </el-popover>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="产品定位结论" prop="产品定位结论">
                    <el-input type="textarea" :rows="3" v-model="form.产品定位结论" autocomplete="off" placeholder="(项目目标)"
                        style="width: 130%;" />
                </el-form-item>
                <el-popover placement="right" width="700" trigger="click">
                        <el-table :data="gridData1">
                            <el-table-column width="150" property="项目负责人" label="项目负责人"></el-table-column>
                            <el-table-column width="150" property="CR结论" label="CR结论"></el-table-column>
                            <el-table-column width="200" property="产品定位结论" label="产品定位结论"></el-table-column>
                            <el-table-column width="150" property="评审通过时间" label="评审通过时间"></el-table-column>
                        </el-table>
                        <el-button type="info" slot="reference" plain
                            style="margin-left: 18%;height: 50px;margin-top: 10px;">评审结论</el-button>
                    </el-popover>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目负责人" prop="项目负责人">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-input v-model="form.项目负责人" :disabled="true" style="width: 90%">
                    </el-input>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left">
                <el-form-item label="项目主管" prop="项目主管">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-input v-model="form.项目主管" :disabled="true" style="width: 40%">
                    </el-input>
                </el-form-item>
                <el-form-item label="流量分析" prop="流量分析">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-input v-model="form.QA" :disabled="true" style="width: 40%">
                    </el-input>
                </el-form-item>
                <el-form-item label="截止时间" prop="截止时间">
                    <el-input v-model="form.截止时间" :disabled="true" style="width: 40%">
                    </el-input>
                </el-form-item>
            </el-form>
        </el-dialog>

        <el-dialog :title="title" :visible="dialogVisible2" width="750px" :before-close="handleClose2">
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目名称" prop="项目名称">
                    <el-input v-model="form.项目名称" autocomplete="off" :disabled="true" style="width: 130%;" />
                </el-form-item>
                <el-form-item>
                    <el-tag style="margin-left: 180%;"
                        :type="(form.评审状态 == '未评审' ? 'info' : (form.评审状态 == '评审通过' ? 'success' : (form.评审状态 == '评审中' ? 'primary' : (form.评审状态 == '延期' ? 'warning' : 'danger'))))">
                        {{ form.评审状态 }}</el-tag>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目内容" prop="项目内容">
                    <el-input type="textarea" :rows="3" v-model="form.项目内容" autocomplete="off" :disabled="true"
                        style="width: 130%;" />
                </el-form-item>
                <el-form-item>
                        <el-popover placement="right" width="700" trigger="click">
                            <el-table :data="gridData">
                                <el-table-column width="150" property="评审人" label="评审人"></el-table-column>
                                <el-table-column width="150" property="评审结果" label="评审结果"></el-table-column>
                                <el-table-column width="200" property="评审意见" label="评审意见"></el-table-column>
                                <!-- <el-table-column width="150" property="延期时间" label="延期时间（若延期）"></el-table-column> -->
                            </el-table>
                            <el-button type="info" slot="reference" plain
                                style="margin-left: 128%;height: 50px;margin-top: 10px;">评审详情</el-button>
                        </el-popover>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="CR结论" prop="CR结论">
                    <el-input type="textarea" :rows="3" v-model="form.CR结论" autocomplete="off" placeholder=""
                        style="width: 130%;" />
                </el-form-item>
                <el-form-item>
                    <el-popover placement="right" width="550" trigger="click">
                        <el-table :data="fileData" max-height="500px">
                            <el-table-column width="200" property="文件名" label="文件名"></el-table-column>
                            <el-table-column width="150" property="上传时间" label="上传时间"></el-table-column>
                            <el-table-column width="150" property="下载">
                                <template slot-scope="scope">
                                    <el-button size="mini" plain type="danger" slot="reference" style="float: right;"
                                        @click="delect_load(scope.row)">删除
                                    </el-button>
                                    <el-button size="mini" plain type="primary" slot="reference"
                                        style="float: right;margin-right: 5px;" @click="download(scope.row)">下载</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-button type="info" slot="reference" plain
                            style="margin-left: 128%;height: 50px;margin-top: 10px;">下载附件</el-button>
                    </el-popover>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="产品定位结论" prop="产品定位结论">
                    <el-input type="textarea" :rows="3" v-model="form.产品定位结论" autocomplete="off" placeholder="(项目目标)"
                        style="width: 130%;" />
                </el-form-item>
                <el-popover placement="right" width="700" trigger="click">
                        <el-table :data="gridData1">
                            <el-table-column width="150" property="项目负责人" label="项目负责人"></el-table-column>
                            <el-table-column width="150" property="CR结论" label="CR结论"></el-table-column>
                            <el-table-column width="200" property="产品定位结论" label="产品定位结论"></el-table-column>
                            <el-table-column width="150" property="评审通过时间" label="评审通过时间"></el-table-column>
                        </el-table>
                        <el-button type="info" slot="reference" plain
                            style="margin-left: 18%;height: 50px;margin-top: 10px;">评审结论</el-button>
                    </el-popover>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目负责人" prop="项目负责人">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-input v-model="form.项目负责人" :disabled="true" style="width: 90%">
                    </el-input>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left" :inline="true">
                <el-form-item label="项目主管" prop="项目主管">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-input v-model="form.项目主管" :disabled="true" style="width: 90%">
                    </el-input>
                </el-form-item>
                <el-select v-model="form.psxx" placeholder="请选择评审选项（必选）">
                        <el-option v-for="item in pinsx" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                </el-select>
            </el-form>
                <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left" :inline="true">
                    <el-form-item label="流量分析" prop="流量分析">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-input v-model="form.QA" :disabled="true" style="width: 90%">
                    </el-input>
                </el-form-item>
                    <el-input v-model="form.评审意见"  autocomplete="off" style="width: 40%;" placeholder="评审意见(必选)" />
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left" :inline="true">
                    <el-form-item label="截止时间" prop="截止时间">
                    <el-input v-model="form.截止时间" :disabled="true" style="width: 90%">
                    </el-input>
                </el-form-item>
                <!-- <el-date-picker v-model="form.latetime" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd"
                        placeholder="选择延期时间（若延期）">
                    </el-date-picker> -->
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="save" :loading="isSending">评审</el-button>
            </span>
        </el-dialog>

        <el-dialog :title="title" :visible="dialogVisible3" width="750px" :before-close="handleClose3">
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目名称" prop="项目名称">
                    <el-input v-model="form.项目名称" autocomplete="off" :disabled="true" style="width: 130%;" />
                </el-form-item>
                <el-form-item>
                    <el-tag style="margin-left: 180%;"
                        :type="(form.评审状态 == '未评审' ? 'info' : (form.评审状态 == '评审通过' ? 'success' : (form.评审状态 == '评审中' ? 'primary' : (form.评审状态 == '延期' ? 'warning' : 'danger'))))">
                        {{ form.评审状态 }}</el-tag>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目内容" prop="项目内容">
                    <el-input type="textarea" :rows="3" v-model="form.项目内容" autocomplete="off" :disabled="true"
                        style="width: 130%;" />
                </el-form-item>
                <el-form-item>
                        <el-popover placement="right" width="600" trigger="click">
                            <el-table :data="gridData">
                                <el-table-column width="150" property="评审人" label="评审人"></el-table-column>
                                <el-table-column width="150" property="评审结果" label="评审结果"></el-table-column>
                                <el-table-column width="200" property="评审意见" label="评审意见"></el-table-column>
                                <!-- <el-table-column width="150" property="延期时间" label="延期时间（若延期）"></el-table-column> -->
                            </el-table>
                            <el-button type="info" slot="reference" plain
                                style="margin-left: 128%;height: 50px;margin-top: 10px;">评审详情</el-button>
                        </el-popover>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目备注" prop="项目备注">
                    <el-input type="textarea" :rows="2" v-model="form.项目备注" autocomplete="off" :disabled="true"
                        style="width: 130%;" />
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目负责人" prop="项目负责人">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-input v-model="form.项目负责人" :disabled="true" style="width: 90%">
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="info" plain style="margin-left: 114%;" @click="download">下载附件</el-button>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left" :inline="true">
                <el-form-item label="项目主管" prop="项目主管">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-input v-model="form.项目主管" :disabled="true" style="width: 90%">
                    </el-input>
                </el-form-item>
                
                <el-select v-model="form.psxx" placeholder="请选择评审选项（必选）">
                        <el-option v-for="item in pinsx" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
            </el-form>
                <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left" :inline="true">
                    <el-form-item label="流量分析" prop="流量分析">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-input v-model="form.QA" :disabled="true" style="width: 90%">
                    </el-input>
                </el-form-item>
                    <el-input v-model="form.评审意见"  autocomplete="off" style="width: 40%;" placeholder="评审意见(必选)" />
                
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left" :inline="true">
                    <el-form-item label="截止时间" prop="截止时间">
                    <el-input v-model="form.截止时间" :disabled="true" style="width: 90%">
                    </el-input>
                </el-form-item>
                <el-date-picker v-model="form.latetime" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd"
                        placeholder="选择延期时间（若延期）">
                    </el-date-picker>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="save1" :loading="isSending">评审</el-button>
            </span>
        </el-dialog>

        <el-dialog :title="title" :visible="dialogVisible4" width="750px" :before-close="handleClose4">
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目名称" prop="项目名称">
                    <el-input v-model="form.项目名称" autocomplete="off" :disabled="true" style="width: 130%;" />
                </el-form-item>
                <el-form-item>
                    <el-tag style="margin-left: 180%;"
                        :type="(form.评审状态 == '未评审' ? 'info' : (form.评审状态 == '评审通过' ? 'success' : (form.评审状态 == '评审中' ? 'primary' : (form.评审状态 == '延期' ? 'warning' : 'danger'))))">
                        {{ form.评审状态 }}</el-tag>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目内容" prop="项目内容">
                    <el-input type="textarea" :rows="3" v-model="form.项目内容" autocomplete="off" :disabled="true"
                        style="width: 130%;" />
                </el-form-item>
                <el-form-item>
                        <el-popover placement="right" width="600" trigger="click">
                            <el-table :data="gridData">
                                <el-table-column width="150" property="评审人" label="评审人"></el-table-column>
                                <el-table-column width="150" property="评审结果" label="评审结果"></el-table-column>
                                <el-table-column width="200" property="评审意见" label="评审意见"></el-table-column>
                                <!-- <el-table-column width="150" property="延期时间" label="延期时间（若延期）"></el-table-column> -->
                            </el-table>
                            <el-button type="info" slot="reference" plain
                                style="margin-left: 128%;height: 50px;margin-top: 10px;">评审详情</el-button>
                        </el-popover>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目备注" prop="项目备注">
                    <el-input type="textarea" :rows="2" v-model="form.项目备注" autocomplete="off" :disabled="true"
                        style="width: 130%;" />
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目负责人" prop="项目负责人">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-input v-model="form.项目负责人" :disabled="true" style="width: 90%">
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="info" plain style="margin-left: 114%;" @click="download">下载附件</el-button>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left" :inline="true">
                <el-form-item label="项目主管" prop="项目主管">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-input v-model="form.项目主管" :disabled="true" style="width: 90%">
                    </el-input>
                </el-form-item>
                
                <el-select v-model="form.psxx" placeholder="请选择评审选项（必选）">
                        <el-option v-for="item in pinsx" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                
            </el-form>
                <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left" :inline="true">
                    <el-form-item label="流量分析" prop="流量分析">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-input v-model="form.QA" :disabled="true" style="width: 90%">
                    </el-input>
                </el-form-item>
                    <el-input v-model="form.评审意见"  autocomplete="off" style="width: 40%;" placeholder="评审意见(必选)" />
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left" :inline="true">
                    <el-form-item label="截止时间" prop="截止时间">
                    <el-input v-model="form.截止时间" :disabled="true" style="width: 90%">
                    </el-input>
                </el-form-item>
                <el-date-picker v-model="form.latetime" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd"
                        placeholder="选择延期时间（若延期）">
                    </el-date-picker>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="save2" :loading="isSending">评审</el-button>
            </span>
        </el-dialog>

        <el-dialog :title="title" :visible="dialogVisible5" width="750px" :before-close="handleClose5">
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目名称" prop="项目名称">
                    <el-input v-model="form.项目名称" autocomplete="off" :disabled="true" style="width: 130%;" />
                </el-form-item>
                <el-form-item>
                    <el-tag style="margin-left: 180%;"
                        :type="(form.评审状态 == '未评审' ? 'info' : (form.评审状态 == '评审通过' ? 'success' : (form.评审状态 == '评审中' ? 'primary' : (form.评审状态 == '延期' ? 'warning' : (form.评审状态 == '延期审核中' ? 'warning' : 'danger')))))">
                        {{ form.评审状态 }}</el-tag>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目内容" prop="项目内容">
                    <el-input type="textarea" :rows="3" v-model="form.项目内容" autocomplete="off" :disabled="true"
                        style="width: 130%;" />
                </el-form-item>
                <el-form-item>
                        <el-popover placement="right" width="600" trigger="click">
                            <el-table :data="gridData">
                                <el-table-column width="150" property="评审人" label="评审人"></el-table-column>
                                <el-table-column width="150" property="评审结果" label="评审结果"></el-table-column>
                                <el-table-column width="200" property="评审意见" label="评审意见"></el-table-column>
                                <!-- <el-table-column width="150" property="延期时间" label="延期时间（若延期）"></el-table-column> -->
                            </el-table>
                            <el-button type="info" slot="reference" plain
                                style="margin-left: 128%;height: 50px;margin-top: 10px;">评审详情</el-button>
                        </el-popover>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目备注" prop="项目备注">
                    <el-input type="textarea" :rows="2" v-model="form.项目备注" autocomplete="off" :disabled="true"
                        style="width: 130%;" />
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left"
                :inline="true">
                <el-form-item label="项目负责人" prop="项目负责人">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-input v-model="form.项目负责人" :disabled="true" style="width: 90%">
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="info" plain style="margin-left: 114%;" @click="download">下载附件</el-button>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left" :inline="true">
                <el-form-item label="项目主管" prop="项目主管">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-input v-model="form.项目主管" :disabled="true" style="width: 90%">
                    </el-input>
                </el-form-item>
            </el-form>
                <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left" :inline="true">
                    <el-form-item label="流量分析" prop="流量分析">
                    <!-- <el-input v-model="form.lv" autocomplete="off" /> -->
                    <el-input v-model="form.QA" :disabled="true" style="width: 90%">
                    </el-input>
                </el-form-item>
            </el-form>
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" label-position="left" :inline="true">
                    <el-form-item label="延期时间" prop="延期时间">
                    <el-input v-model="form.截止时间" :disabled="true" style="width: 90%">
                    </el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="up_latetime1" :loading="isSending">允许延期</el-button>
            </span>
        </el-dialog>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            input: '',
            activeIndex: '1',
            activeIndex2: '1',
            bottercold: '',
            dialogVisible1: false,
            dialogVisible2: false,
            dialogVisible3: false,
            dialogVisible4: false,
            isSending: false,
            form: {},
            reviewtabledata: [],
            size: '',
            title: '',
            visible: false,
            tableData: [],
            tableDataover: [],
            psname: {
                项目可行性评审: '项目可行性评审',
                图片需求评审: '图片需求评审',
                listing评审: 'listing评审',
                推广评审: '推广评审',
                广告目标及方案评审: '广告目标及方案评审',
                三个月阶段项目可持续性评审: '三个月阶段项目可持续性评审',
                两个月项目可持续性评审: '两个月项目可持续性评审',
                // 二次备货策略或首月销售情况评审: '二次备货策略或首月销售情况评审',
                广告第二星期效果评审:'广告第二星期效果评审',
                广告第三星期效果评审:'广告第三星期效果评审',
                广告第四星期效果评审:'广告第四星期效果评审',
                广告首星期效果评审: '广告首星期效果评审',
                项目结项评审: '项目结项评审',
            },
            options: [{
                value: '李健东',
                label: '李健东'
            }, {
                value: '温智超',
                label: '温智超'
            }, {
                value: '陈志芳',
                label: '陈志芳'
            }, {
                value: '李兆轩',
                label: '李兆轩'
            }, {
                value: '张梅丽',
                label: '张梅丽'
            }, {
                value: '罗航',
                label: '罗航'
            }, {
                value: '李秀娟',
                label: '李秀娟'
            }, {
                value: '张莉敏',
                label: '张莉敏'
            }, {
                value: '卢凤颖',
                label: '卢凤颖'
            }, {
                value: '林宝玲',
                label: '林宝玲'
            }, {
                value: '周才德',
                label: '周才德'
            }],
            pinsx:[{
                value: '通过',
                label: '通过'
            // },{
            //     value: '延期',
            //     label: '延期'
            // },{
                },{
                value: '不通过',
                label: '不通过'
            }],
            value: '',
            value1: [],
            value2: [],
            value3: [],
            formLabelWidth: '80px',
            timer: null,
            rrname: '',
            reviewer_dataname: '',
            gridData: [],
            gridData1: [],
            fileData:[],
            datason:[]
        }
    },
    // created(){
    //     const that = this
    //     that.rrname = this.$route.query.reviewname
    //     // this.init(this.rrname)
    // },
    mounted() {
        const that = this
        that.rrname = this.$route.query.reviewname
        this.init()
        // this.init(that.rrname)
        // 接受reviewname的参数，并将他赋给rrname
        // 像后端发送rrname的参数，让后端向数据库执行只接受有关rrname的全部字段并将他返回给前端
        // router.replace({ path: '/dashboard/review/review_table'})
    },
    methods: {
        init() {
            let bbname = this.rrname
            console.log(bbname)
            this.$axios.get(`/vue/review/review_table_all?bbname=${bbname}`).then(res => {
                console.log(res);
                this.reviewtabledata = res.data
            })
                .console(error => {
                    console.log(error)
                })
        },
        handleSelect(key, keyPath) {
            console.log(key, keyPath);
        },
        select2() {
            let pszt = this.reviewtabledata[0].推广评审
            if (pszt == '未评审') {
                console.log('未评审')
                alert("负责人还未发起评审")
            } else if(pszt == '评审中' || pszt == '超期' || pszt == '不通过'){
                this.dialogVisible2 = true
                this.title = this.psname.推广评审
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.review_data_son(this.title, this.reviewer_dataname)
            }else {
                this.dialogVisible1 = true
                this.title = this.psname.推广评审
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.review_data_son(this.title, this.reviewer_dataname)
            }
        },
        select3() {
            let pszt = this.reviewtabledata[0].listing评审
            if (pszt == '未评审') {
                console.log('未评审')
                alert("负责人还未发起评审")
            }else if(pszt == '评审中' || pszt == '超期' || pszt == '不通过'){
                this.dialogVisible2 = true
                this.title = this.psname.listing评审
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.review_data_son(this.title, this.reviewer_dataname)
            } else {
                this.dialogVisible1 = true
                this.title = this.psname.listing评审
                this.reviewer_dataname = this.reviewtabledata[0].项目名称
                this.review_data_son(this.title, this.reviewer_dataname)
            }
        },
        handEdit(index, row) {
            this.dialogVisible = true
            this.title = '编辑记录'
            this.form = JSON.parse(JSON.stringify(row))
        },
        reviewer_data(a, b) {
            this.$axios.post(`/vue/review/reviewer_data`,{title:a,rename:b}).then(res => {
                console.log(res)
                this.gridData = res.data
                this.gridData1 = res.data1
                this.fileData = res.data2
            }).console(error => {
                    console.log(error)
                })
        },
        review_data_son(a, b){
            this.$axios.post(`/vue/review/review_data_son`,{title:a,rename:b}).then(res => {
                console.log(res)
                this.datason = res.data
                this.reread(a,b)
            }).console(error => {
                    console.log(error)
                })
        },
        reread(a,b){
            this.form = this.datason[0]
            this.form.评审点 = this.title
            this.reviewer_data(a,b)
        },
        handDelete(index, row) {
            let id = JSON.parse(JSON.stringify(row)).id
            console.log(id)
            this.$axios.delete(`/vue/purchase_review/delete?id=${id}`).then(res => {
                if (res.code == 200) {
                    this.$notify.success({
                        title: '成功',
                        message: res.msg,
                        duration: 2000
                    })
                    this.init()
                } else {
                    this.$notify.success({
                        title: '失败',
                        message: res.msg,
                        duration: 2000
                    })
                }
            }).console(error => {
                    console.log(error)
                    alert('发生错误:=='+error)
                })
        },
        Conditionupdt_y(index, row) {
            let id = JSON.parse(JSON.stringify(row)).id
            console.log(id)
            this.$axios.post(`/vue/purchase_review/condition_y?id=${id}`).then(res => {
                if (res.code == 200) {
                    this.$notify.success({
                        title: '成功',
                        message: res.msg,
                        duration: 2000
                    })
                    this.init()
                } else {
                    this.$notify.success({
                        title: '失败',
                        message: res.msg,
                        duration: 2000
                    })
                }
            }).console(error => {
                    console.log(error)
                    alert('发生错误:=='+error)
                })
        },
        Conditionupdt_n(index, row) {
            let id = JSON.parse(JSON.stringify(row)).id
            console.log(id)
            this.$axios.post(`/vue/purchase_review/condition_n?id=${id}`).then(res => {
                if (res.code == 200) {
                    this.$notify.success({
                        title: '成功',
                        message: res.msg,
                        duration: 2000
                    })
                    this.init()
                } else {
                    this.$notify.success({
                        title: '失败',
                        message: res.msg,
                        duration: 2000
                    })
                }
            }).console(error => {
                    console.log(error)
                    alert('发生错误:=='+error)
                })
        },
        handleClose1() {
            this.dialogVisible1 = false
            this.init()
        },
        handleClose2() {
            this.dialogVisible2 = false
            this.init()
        },
        handleClose3() {
            this.dialogVisible3 = false
            this.init()
        },
        handleClose4() {
            this.dialogVisible4 = false
            this.init()
        },
        handleClose5() {
            this.dialogVisible5 = false
            this.init()
        },
        handleClose6() {
            this.dialogVisible6 = false
            this.init()
        },
        handleClose7() {
            this.dialogVisible7 = false
            this.init()
        },
        handleClose8() {
            this.dialogVisible8 = false
            this.init()
        },
        handleClose9() {
            this.dialogVisible9 = false
            this.init()
        },
        reset() {
            let id = undefined
            if ('id' in this.form) {
                id = this.form.id
            }
            this.form = {}
            if (id != undefined) this.form.id = id
        },
        save() {
            console.log(this.form);
            this.isSending = true
            this.$axios.post('/vue/review/reviewer_add', this.form).then(res => {
                if (res.code == 200) {
                    let _this = this
                    this.$notify.success({
                        title: '成功',
                        message: res.msg,
                        duration: 2000,
                        onClose: function () { _this.handleClose2() }
                    })
                    this.isSending = false
                } else {
                    this.$notify.error({
                        title: '错误',
                        message: res.msg,
                        duration: 2000
                    });
                    this.isSending = false
                }
            }).catch(error => {
                    console.log(error)
                    alert('发生错误:=='+'请检查内容是否填写完整')
                    this.isSending = false
                })
        },
        save1() {
            console.log(this.form);
            this.isSending = true
            this.$axios.post('/vue/review/reviewer_add_gg', this.form).then(res => {
                if (res.code == 200) {
                    let _this = this
                    this.$notify.success({
                        title: '成功',
                        message: res.msg,
                        duration: 2000,
                        onClose: function () { _this.handleClose3() }
                    })
                    this.isSending = false
                } else {
                    this.$notify.error({
                        title: '错误',
                        message: res.msg,
                        duration: 2000
                    });
                    this.isSending = false
                }
            }).catch(error => {
                    console.log(error)
                    alert('发生错误:=='+'请检查内容是否填写完整,评审不为延期不需要填写日期')
                    this.isSending = false
                })
        },
        save2() {
            console.log(this.form);
            this.isSending = true
            this.$axios.post('/vue/review/reviewer_add_foret', this.form).then(res => {
                if (res.code == 200) {
                    let _this = this
                    this.$notify.success({
                        title: '成功',
                        message: res.msg,
                        duration: 2000,
                        onClose: function () { _this.handleClose4() }
                    })
                    this.isSending = false
                } else {
                    this.$notify.error({
                        title: '错误',
                        message: res.msg,
                        duration: 2000
                    });
                    this.isSending = false
                }
            }).catch(error => {
                    console.log(error)
                    alert('发生错误:=='+'请检查内容是否填写完整,评审不为延期不需要填写日期')
                    this.isSending = false
                })
        },
        up_latetime1() {
            console.log(this.form);
            this.isSending = true
            this.$axios.post('/vue/review/up_latetime1', this.form).then(res => {
                if (res.code == 200) {
                    let _this = this
                    this.$notify.success({
                        title: '成功',
                        message: res.msg,
                        duration: 2000,
                        onClose: function () { _this.handleClose5() }
                    })
                    this.isSending = false
                } else {
                    this.$notify.error({
                        title: '错误',
                        message: res.msg,
                        duration: 2000
                    });
                    this.isSending = false
                }
            }).catch(error => {
                    console.log(error)
                    alert('发生错误:=='+'发生未知错误')
                    this.isSending = false
                })
        },
        download(row) {
            let url = "https://kebeidianzi.com/vue/review/review_download/"+ row.文件名 + "?name=" + row.项目名称 + "&er=" + row.评审点
            this.$axios.get(`/vue/review/review_download/` + row.文件名, { params: { name: row.项目名称, er: row.评审点 }, responseType: 'blob' }).catch(error => {
                console.log("错误", error)
                // alert('下载发生错误:==' + error)
            })
            window.open(url, '_blank')
        },
        delect_load(row) {
            this.$axios.post(`/vue/review/delect_load`, { name: row.项目名称, filename: row.文件名, er: row.评审点 }).then(res => {
                if (res.code == 200) {
                    let _this = this
                    this.$notify.success({
                        title: '成功',
                        message: res.msg,
                        duration: 2000,
                        onClose: function () { _this.handleClose1() }
                    });
                } else {
                    this.$notify.error({
                        title: '错误',
                        message: res.msg,
                        duration: 2000
                    });
                }
                this.review_data_son(this.title, this.reviewer_dataname)
            }).console(error => {
                console.log(error)
                alert('发生错误:==' + error)
            })
        }
    },
    rightclick() {
        this.visible = true
    },
    // watch:{
    //     '$route': function(to, from){
    //         this.$store.dispatch('updateActive')
    //     }
    // }
}
</script>
  
<style>


</style>

<style scoped="scoped">
::v-deep .warm tbody tr>td {
    background-color: rgba(236, 236, 236, 0.8);
}

.el-button--cyan.is-active,
.el-button--cyan:active {
  background: #d6da04;
  border-color: #d6da04;
  color: #FFFFFF;
}
 
.el-button--cyan:focus,
.el-button--cyan:hover {
  background: #d6da04;
  border-color: #d6da04;
  color: #FFFFFF;
}
 
.el-button--cyan {
  background-color: #d6da04;
  border-color: #d6da04;
  color: #FFFFFF;
}
</style>