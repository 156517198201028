import { render, staticRenderFns } from "./Reviewer_table.vue?vue&type=template&id=0cfe905c&scoped=true"
import script from "./Reviewer_table.vue?vue&type=script&lang=js"
export * from "./Reviewer_table.vue?vue&type=script&lang=js"
import style1 from "./Reviewer_table.vue?vue&type=style&index=1&id=0cfe905c&prod&scoped=scoped&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cfe905c",
  null
  
)

export default component.exports